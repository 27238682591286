<template>
  <FlowResolver
    :flow="flow"
    :router="$router"
    :route="$route"
    :lang="$store.getters.language.lang"
    :callback="submit"
    :block-init="blockInit"
    @handle-show-loader="handleLoader($event)"
    @form="handlerForm"
    v-if="!loading && flow"
  />
</template>

<script>
import { FlowResolver, Flow } from '@seliaco/red-panda'

export default {
  name: 'FlowReader',
  components: {
    FlowResolver
  },
  data () {
    return {
      flow: null,
      loading: false,
      blockInit: ''
    }
  },
  mounted () {
    this.init()
  },
  methods: {
    async init () {
      this.blockInit =
        this.$route.query.blockInit || this.$route.query.block || ''

      const flowKey = this.$route.params.flowKey || 'FLOW_READER'

      this.flow = await Flow.get(flowKey)
    },
    handleLoader (option) {
      if (option) {
        this.$loader.handleShowLoader({
          lang: this.$store.getters.language.lang,
          type: 'degrade',
          key: 'MATCHING'
        })
      } else {
        this.$loader.handleRemoveLoader()
      }
    },
    handlerForm () {},
    submit () {
      return this.$router.push({
        path: this.flow.exit_path ? this.flow.exit_path : '/matching'
      })
    }
  }
}
</script>
